<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card :bordered="false" v-else>
      <!-- 搜索区域 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="代销单号">
                <a-input v-model.trim="queryParam.agent_num" placeholder="输入代销单号进行搜索" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="代销时间">
                <a-range-picker @change="onDateChange"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="供应商">
                <a-select
                  showSearch
                  allowClear
                  :filter-option="filterOption"
                  placeholder="选择供应商"
                  v-model="queryParam.supplier_ids"
                  mode="multiple"
                >
                  <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item label="订购人">
                <a-input v-model.trim="queryParam.order_person" placeholder="请输入订购人搜索" />
              </a-form-item>
            </a-col>
            <a-col :span="1">
              <a-form-item>
                <a @click="toggle"><a-icon :type="expand ? 'up' : 'down'" /></a>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="GLOBAL.queryRowGutter">

            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="订单类型">
                <a-select
                  style="width: 100%"
                  v-model="queryParam.order_types"
                  placeholder="请选择订单类型"
                  allowClear
                  mode="multiple">
                  <a-select-option :value="1">普通</a-select-option>
                  <a-select-option :value="2">团购</a-select-option>
                  <a-select-option :value="3">工会</a-select-option>
                  <a-select-option :value="4">内购</a-select-option>
                  <a-select-option :value="5">饭卡</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="货名">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择货名"
                  style="width: 100%; z-index: 2;"
                  :value="goodInfo"
                  :filter-option="false"
                  :not-found-content="fetching2 ? undefined : null"
                  @search="getGoodInfo"
                  @change="changeGoodInfo"
                  mode="multiple"
                >
                  <template v-if="fetching2" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in goodOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="交易号">
                <a-input v-model.trim="queryParam.trx_num" placeholder="输入交易号进行搜索" />
              </a-form-item>
            </a-col>

            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="收货人">
                <a-input v-model.trim="queryParam.receiving_person" placeholder="输入收货人进行搜索" />
              </a-form-item>
            </a-col>

            <a-col :span="12" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="购买单位">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :value="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                  mode="multiple"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in buyerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="备注">
                <a-input v-model.trim="queryParam.remark" placeholder="根据备注信息进行搜索" allowClear />
              </a-form-item>
            </a-col>

            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="送货方式">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择送货方式"
                  style="width: 100%"
                  :value="escortModeInfo"
                  :filter-option="false"
                  :not-found-content="fetching3 ? undefined : null"
                  @search="getEscortMode"
                  @change="changeEscortMode"
                >
                  <template v-if="fetching3" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in escortModeOps" :key="op.id">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="快递单号">
                <a-input v-model.trim="queryParam.courier_no" placeholder="根据快递单号进行搜索" allowClear />
              </a-form-item>
            </a-col>

          </a-row>
        </a-form>
      </div>

      <!-- 操作按钮 -->
      <div class="table-operator">
        <a-button type="query" icon="search" @click="queryList">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="danger" icon="delete" :disabled="selectedRowKeys.length === 0" @click="handleDelete('all')">删除</a-button>
      </div>

      <!-- 表单区域 -->
      <s-table
        ref="sellTable"
        size="small"
        rowKey="id"
        :scroll="{ x: 'max-content', y: 450 }"
        :columns="columns"
        :data="loadData"
        :alert="{
          show: true,
          msg: showTotal
        }"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="orderStatus" slot-scope="text">
          <a-tag :color="text === 1 ? '#4cd964' : '#ff4d4f'">
            {{ GLOBAL.wmsOrderStatus[text].name }}
          </a-tag>
        </span>
        <span slot="goodName" slot-scope="text">
          <a-popover placement="bottom">
            <template v-if="text && text.length > 0">
              <span style="color: #FAAD14">{{ text.length !== 0 ? ` (${text.length})单 ` : null }}</span>
              <span>{{ text[0] }}</span>
            </template>
            <template slot="content">
              <span v-for="(item, i) in text" :key="i" style="display:block">{{ item }}</span>
            </template>
          </a-popover>
        </span>
        <span slot="goodNum" slot-scope="text">
          <span v-for="(item, i) in text" :key="i" style="display:block">{{ item }}</span>
        </span>
        <span slot="cashRecord" slot-scope="text">
          <span v-for="item in text" :key="item.id" style="display:block">{{ getCashRecordInfo(item) }}</span>
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
            <a-divider type="vertical" />
            <a @click="printSaleAgentSheet(record)">销售代销单</a>
          </template>
        </span>
        <span slot="courierInfoList" slot-scope="text">
          <span v-for="item in text" :key="item.id" style="display: block">
            快递单号：{{ item.courier_no }} / 发货日期：{{ moment(item.send_good_date).format('YYYY/MM/DD') }} /
            快递费：{{ item.courier_fee }} / 保险费：{{ item.safe_fee }} / 包装费：{{ item.package_fee }} /
            包裹数量：{{ item.package_num }} / 体积立方：{{ item.cube }} / 重量：{{ item.weight }}
          </span>
        </span>
      </s-table>

      <a-modal
        :visible="printSaleAgentSheetVisible"
        :confirmLoading="printSaleAgentSheetLoading"
        :width="1250"
        :maskClosable="false"
        title="销售代销单"
        @cancel="printSaleAgentSheetVisible = false">
        <div id="printSaleAgentSheetBill">
          <SaleAgentSheetBill
            :currentData="currentData"
            :balanceData="balanceData">
          </SaleAgentSheetBill>
        </div>
        <template slot="footer">
          <a-button key="submit"
                    type="primary"
                    id="printSaleAgentSheetBillBtn"
                    v-print="printSaleAgentSheetBill">确认打印</a-button>
          <a-button type="primary" @click="printSaleAgentSheetVisible = false">关闭</a-button>
        </template>
      </a-modal>

    </a-card>
  </keep-alive>
</template>
<script>
import { STable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import { getAgentPage,
deleteAgentByID,
getSaleAgentSheet,
getGoodInfo,
getAllEscortMode,
agentAllTotal
} from '@/api/wms/sale'
import { getCustomer, getSupplier } from '@/api/common'
import debounce from 'lodash/debounce';
import moment from 'moment'
import SaleAgentSheetBill from '@/views/wms/sale/bill/SaleAgentSheetBill'

export default {
  components: {
    STable,
    SaleAgentSheetBill
  },
  data() {
    this.lastFetchId = 0;
    this.lastFetchId2 = 0;
    this.lastFetchId3 = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    this.getGoodInfo = debounce(this.getGoodInfo, 800);
    this.getEscortMode = debounce(this.getEscortMode, 800);
    return {
      queryParam: {},
      buyerOps: [], // 购买单位
      supplierOps: [], // 供应商
      goodOps: [], // 商品信息
      customerInfo: undefined,
      goodInfo: undefined,
      fetching: false,
      fetching2: false,
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          fixed: 'left'
        },
        {
          title: '代销单号',
          dataIndex: 'agent_num',
          width: 150
        },
        {
          title: '订单状态',
          dataIndex: 'order_status',
          scopedSlots: { customRender: 'orderStatus' },
          width: 80
        },
        {
          title: '订单类型',
          dataIndex: 'order_type',
          customRender: (value) => {
            if (value) {
              switch (value) {
                case 1:
                  return '普通';
                case 2:
                  return '团购';
                case 3:
                  return '工会';
                case 4:
                  return '内购';
                case 5:
                  return '饭卡';
              }
            }
          },
          width: 80
        },
        {
          title: '货物名称',
          dataIndex: 'good_name',
          scopedSlots: { customRender: 'goodName' },
          width: 400
        },
        {
          title: '代销时间',
          dataIndex: 'agent_date',
          width: 120
        },
        {
          title: '购买单位',
          dataIndex: 'buyer_name',
          width: 180
        },
        {
          title: '送货方式',
          dataIndex: 'escort_mode_name',
          width: 80
        },
        {
          title: `收货地址`,
          dataIndex: `receiving_address_name`,
          width: 300
        },
        {
          title: `收货人`,
          dataIndex: `receiving_person`,
          width: 80
        },
        {
          title: `收货人电话`,
          dataIndex: `receiving_phone`,
          width: 100
        },
        {
          title: '现金收费',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecord' },
          width: 300
        },
        {
          title: '快递单信息',
          dataIndex: 'courier_info_list',
          scopedSlots: { customRender: 'courierInfoList' },
          width: 300
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 150
        },
        {
          title: '录入人',
          dataIndex: 'creator',
          width: 80
        },
        {
          title: '录入时间',
          dataIndex: 'created_at',
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: `right`,
          scopedSlots: { customRender: 'action' },
          width: 200
        }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        return getAgentPage(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      showTotal: '数量：0; 金额：0',
      refundFormVisible: false,
      confirmLoading: false,
      currentData: {},
      balanceData: [],
      printSaleAgentSheetVisible: false,
      printSaleAgentSheetLoading: false,
      printSaleAgentSheetBill: {
        id: `printSaleAgentSheetBill`,
        opTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      escortModeInfo: undefined,
      escortModeOps: [], // 送货方式
      fetching3: false,
      // 默认撑开
      expand: true
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'WmsAgent' && this.queryParam) {
        newRoute.meta.title = '代销'
        this.$nextTick(_ => {
          this.$refs.sellTable.refresh(newRoute.query.isEdit)
        })
      }
    }
  },
  created() {
    getSupplier({ department: 8 }).then(res => {
      this.supplierOps = res
    })
    this.getAgentTotal()
  },
  methods: {
    moment,
    toggle() {
      this.expand = !this.expand
    },
    queryList() {
      this.$refs.sellTable.refresh(true)
      this.getAgentTotal()
    },
    getAgentTotal() {
      agentAllTotal(this.queryParam).then(res => {
        this.showTotal = `数量：${res.total}; 金额：${res.money}`
      })
    },
    getEscortMode(value) {
      this.lastFetchId3 += 1
      const fetchId = this.lastFetchId3
      this.escortModeOps = []
      this.fetching3 = true
      getAllEscortMode({ name: value }).then(res => {
        if (fetchId !== this.lastFetchId3) {
          return
        }
        this.escortModeOps = res
        this.fetching3 = false
      })
    },
    changeEscortMode(value) {
      if (value) {
        this.escortModeInfo = value
        this.queryParam['escort_mode'] = value.key
        this.queryParam['escort_mode_name'] = value.label
      } else {
        this.escortModeInfo = undefined
        this.queryParam['escort_mode'] = null
        this.queryParam['escort_mode_name'] = null
      }
      this.escortModeOps = []
      this.fetching3 = false
    },
    getGoodInfo(value) {
      this.lastFetchId2 += 1;
      const fetchId = this.lastFetchId2;
      this.goodOps = [];
      this.fetching2 = true;
      getGoodInfo({
        goodName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId2) {
          return;
        }
        this.goodOps = res;
        this.fetching2 = false;
      });
    },
    changeGoodInfo(value) {
      this.goodInfo = value;
      const ids = [];
      if (value.length !== 0) {
        value.forEach(v => ids.push(v.key));
      }
      this.queryParam['good_ids'] = ids;
      this.goodOps = [];
      this.fetching2 = false;
    },
    getCashRecordInfo(record) {
      let result = ''
      if (record.cash_status === 1) {
        result = `已完成 交易号:${record.trx_id} 付款时间:${record.pay_date}`
      }
      if (record.cash_status === 0) {
        if (record.is_expire === 1) {
          result = '已过期'
        } else {
          result = '待支付'
        }
      }
      return result.concat(`合计:${record.amount}`)
    },
    // 销售代销单
    printSaleAgentSheet(record) {
      getSaleAgentSheet({
        agentId: record.id
      }).then(res => {
        this.balanceData = res;
      }).finally(_ => {
        this.currentData = record;
        this.printSaleAgentSheetVisible = true;
      });
    },
    handleCreate() {
      this.$router.replace({
        name: 'AgentCUR',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.$router.replace({
        name: 'AgentCUR',
        params: record
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.buyerOps = [];
      this.fetching = true;
      getCustomer({
        department: 8,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.buyerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value;
      const ids = [];
      if (value.length !== 0) {
        value.forEach(item => ids.push(item.key));
      }
      this.queryParam['buyer_ids'] = ids;
      this.buyerOps = [];
      this.fetching = false;
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteAgentByID({ ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.sellTable.refresh(true)
          })
        }
      })
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['start_date'] = startDate
        this.queryParam['end_date'] = endDate
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          getFirstLetter(option.componentOptions.children[0].text).toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
